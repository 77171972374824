/* eslint-disable valid-jsdoc */
/* @flow */

import * as Sentry from '@sentry/react';
// eslint-disable-next-line unused-imports/no-unused-imports
// import { Participant as ZoomParticipant } from '@zoom/videosdk';
import Video, {
  RemoteParticipant,
  Room,
  TwilioParticipant,
} from 'twilio-video';

import type { Braindate } from '@braindate/domain/lib/braindate/type';
import {
  getBraindateEndTime,
  getBraindateId,
} from '@braindate/domain/lib/braindate/util';
import type { Conversation } from '@braindate/domain/lib/conversation/type';
import { getConversationParticipants } from '@braindate/domain/lib/conversation/util';
import type { BraindateEvent } from '@braindate/domain/lib/event/type';
import { getEventPlugins } from '@braindate/domain/lib/event/util';
import { isFishbowlTopic } from '@braindate/domain/lib/topic/util';
import type { User } from '@braindate/domain/lib/user/type';
import { getUserId } from '@braindate/domain/lib/user/util';

import { FISHBOWL_STATUS } from 'src/shared/app/base/plugin/virtual-braindate/constant/virtualBraindateConstants';
import type { UserSocketParticipant } from 'src/shared/app/base/plugin/virtual-braindate/type/virtualTypes';
import { isErrorReportingEnabled } from 'src/shared/app/base/selector/appEnvSelectors';
import { createDateTimeFromISO } from 'src/shared/app/booking/util/dateUtils';
import {
  videoCallPlugin,
  videoCallPluginProviderTwilio,
  videoCallPluginProviderZoom,
} from 'src/shared/app/feature/settings/featureSettings';
import { NOTIFICATION_COMPONENT_KEYS } from 'src/shared/app/notification/constant/notificationComponentsMap';
import {
  notificationInfoLevel,
  notificationTopType,
} from 'src/shared/app/notification/constant/notificationConstants';
import type { Notification as NotificationType } from 'src/shared/app/notification/type/notificationTypes';
import { getBraindateTopic } from 'src/shared/domain/braindate/util/braindateUtil';

import { getFullLabel } from 'plugin/virtual-braindate/component/track/VirtualBraindateParticipantLabel';

import { isSpeaker } from './virtualBraindateFishbowlUtils';

export function silentError(e: Error) {
  if (isErrorReportingEnabled) {
    Sentry.captureException(e);
  }
  // eslint-disable-next-line no-console
  console.error(e);
}

export function getParticipantsBindToUsersSocketParticipants(
  context: Object,
  conversation?: Conversation,
): UserSocketParticipant[] {
  const participants =
    conversation && getConversationParticipants(conversation);
  if (!context.participants || !participants) {
    return [];
  }
  return context.participants
    .map((APIp) => {
      const conversationParticipant = participants.find(
        (p) => APIp.user === getUserId(p),
      );
      if (!conversationParticipant) return false;
      return {
        ...conversationParticipant,
        fishbowl_data: APIp,
      };
    })
    .filter(Boolean);
}

export function buildConversationParticipants(
  isFishbowl: boolean,
  participants: RemoteParticipant[],
  conversationSocketParticipants: UserSocketParticipant[],
  localStatus?: string,
  room?: Room,
): RemoteParticipant[] {
  if (!isFishbowl) return participants;

  const fishbowlParticipants = participants.filter((participant) => {
    const userSocketParticipant = conversationSocketParticipants.find(
      (user) => getUserId(user) === Number(participant.identity),
    );

    return userSocketParticipant && isSpeaker(userSocketParticipant);
  });

  if (localStatus !== FISHBOWL_STATUS.WATCHER && room?.localParticipant) {
    fishbowlParticipants.push(room?.localParticipant);
  }

  return fishbowlParticipants;
}

export function buildVirtualBraindateEarlyReminder(
  braindate: Braindate,
): NotificationType<> {
  const topic = getBraindateTopic(braindate);
  const isFishbowl = isFishbowlTopic(topic);

  return {
    id: `virtual-braindate-early-reminder-${getBraindateId(braindate)}`,
    level: notificationInfoLevel,
    type: notificationTopType,
    componentKey: NOTIFICATION_COMPONENT_KEYS.VIRTUAL_BRAINDATE_EARLY_REMINDER,
    data: braindate,
    exclude: isFishbowl ? ['topicRoute'] : ['braindateRoute'],
  };
}

export function getSecondsBeforeBraindateEndTime(braindate: Braindate): number {
  // $FlowIssue
  const endTime = createDateTimeFromISO(getBraindateEndTime(braindate));
  const { milliseconds } = endTime.diffNow();
  const seconds = milliseconds / 1000;

  return Math.round(seconds);
}

export function isProvider(event: BraindateEvent, provider: string) {
  if (!provider) {
    return false;
  }
  const plugin = getEventPlugins(event)[videoCallPlugin];
  return plugin && plugin.provider === provider;
}
/**
 * Return True when the Provider is all handled by Braindate App
 * Return False if the PRovider is External
 * @returns boolean
 */

export function isProviderInApp(event: BraindateEvent) {
  return (
    isProvider(event, videoCallPluginProviderTwilio) ||
    isProvider(event, videoCallPluginProviderZoom)
  );
}

export async function isSupported(event: BraindateEvent) {
  if (isProvider(event, videoCallPluginProviderTwilio)) {
    return Video.isSupported;
  }
  if (isProvider(event, videoCallPluginProviderZoom)) {
    // https://developers.zoom.us/docs/video-sdk/web/browser-support/
    const importZoom = await import('@zoom/videosdk');
    const ZoomVideo = importZoom.default;
    if (
      ZoomVideo.checkSystemRequirements().video &&
      ZoomVideo.checkSystemRequirements().audio
    ) {
      return true;
    }
    return false;
  }
  return true;
}

export function getConversationUserFromParticipant(
  conversation: ?Conversation,
  participant: ?TwilioParticipant,
): ?User {
  if (!participant) return null;
  const participantIdentity = getIdentity(participant);
  const conversationParticipants =
    conversation && getConversationParticipants(conversation);
  const conversationParticipant =
    conversationParticipants &&
    conversationParticipants.find(
      (current) =>
        current.id === participantIdentity ||
        current.uuid === participantIdentity,
    );
  return conversationParticipant;
}

export const getIdentity = (
  participant: TwilioParticipant | ZoomParticipant,
) => {
  if (participant.sid) {
    return Number(participant.identity);
  }
  return participant?.userIdentity;
};
export const getFullName = (
  participant: TwilioParticipant | ZoomParticipant,
) => {
  if (participant.sid) {
    return getFullLabel(participant);
  }
  return participant.displayName;
};
export const getParticipantId = (
  participant: TwilioParticipant | ZoomParticipant,
) => {
  if (participant?.sid) {
    return participant?.sid;
  }
  return participant?.userId;
};

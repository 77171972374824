/* @flow */

import { skipToken } from '@reduxjs/toolkit/query';
import { useDispatch } from 'react-redux';

import {
  getBraindateConversation,
  getBraindateId,
} from '@braindate/domain/lib/braindate/util';
import { getConversationId } from '@braindate/domain/lib/conversation/util';

import { useGetBraindateQuery } from 'src/shared/app/base/api/endpoint/braindatesEndpoint';
import { useGetConversationQuery } from 'src/shared/app/base/api/endpoint/conversationsEndpoint';
import useRefState from 'src/shared/app/base/hook/useRefState';
import { virtualCallEndpoint } from 'src/shared/app/base/settings/endpointSettings';
import { showPostBraindateFeedback } from 'src/shared/app/post-braindate/action/postBraindateActions';

import LazyVirtualBraindateProvider from 'plugin/virtual-braindate/component/LazyVirtualBraindateProvider';
import useVirtualBraindateOverlayShown from 'plugin/virtual-braindate/hook/useVirtualBraindateOverlayShown';
import { setOverlayShown } from 'plugin/virtual-braindate/slice/virtualBraindateSlice';

const VideoCallMainWrapper = () => {
  const dispatch = useDispatch();

  const braindateOverlayShown = useVirtualBraindateOverlayShown();

  const [, setIsFeedbackModalNeedsToBeShown, isFeedbackModalNeedsToBeShownRef] =
    useRefState(false);

  const onOverlayClose = () => {
    dispatch(setOverlayShown(null));

    if (isFeedbackModalNeedsToBeShownRef.current && braindate) {
      dispatch(showPostBraindateFeedback(braindate));
    }
  };

  const { data: braindate } = useGetBraindateQuery(
    braindateOverlayShown || skipToken,
  );
  const braindateConversation = braindate
    ? getBraindateConversation(braindate)
    : null;

  const { data: queryConversation } = useGetConversationQuery(
    braindateConversation
      ? getConversationId(braindateConversation)
      : skipToken,
    { skip: !braindateConversation },
  );

  const conversation = queryConversation || braindateConversation;
  if (!braindate || !conversation || !braindateOverlayShown) return null;

  return (
    <LazyVirtualBraindateProvider
      // @TODO TEMP URL
      joinURL={virtualCallEndpoint(getBraindateId(braindate))}
      braindate={braindate}
      conversation={conversation}
      isHidden={false}
      handleClose={onOverlayClose}
      onActivateFeedbackForm={() => setIsFeedbackModalNeedsToBeShown(true)}
      onForceShowClick={onOverlayClose}
    />
  );
};

export default VideoCallMainWrapper;

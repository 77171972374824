import {
  videoCallPluginProviderTwilio,
  videoCallPluginProviderZoom,
} from 'src/shared/app/feature/settings/featureSettings';
import useScreenShareParticipantTwilio from '../providers/twilio/lib/src/hooks/useScreenShareParticipant/useScreenShareParticipant';
import useScreenShareParticipantZoom from '../providers/zoom/hooks/useScreenShareParticipant';
import useVideoCallObjectsMapping from './useVideoCallObjectsMapping';

const providersHooks = {
  [videoCallPluginProviderTwilio]: useScreenShareParticipantTwilio,
  [videoCallPluginProviderZoom]: useScreenShareParticipantZoom,
};

function useScreenShareParticipant() {
  const useProvider = useVideoCallObjectsMapping(providersHooks);

  return useProvider();
}

export default useScreenShareParticipant;

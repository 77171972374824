/* @flow */

// eslint-disable-next-line import/no-extraneous-dependencies
import { parse } from 'url';

import type { BraindateEvent } from '@braindate/domain/lib/event/type';

import {
  loginFromParam,
  tokenQueryParam,
} from 'src/shared/app/base/route/setting/querySettings';
import { saveTokenInCookie } from 'src/shared/app/base/util/tokenUtils';

export function injectTokenInCookie(event: BraindateEvent): void {
  // @TODO - TEMP-AUTH
  const queryToken = getQueryToken(encodeURI(window.location.href));
  if (queryToken) {
    // @TODO - TEMP-AUTH Use env provider
    saveTokenInCookie(queryToken, true, event);
  }
}

export function getQueryToken(url: string): string {
  const { query } = parse(url, true);
  const queryToken = query[tokenQueryParam];
  return queryToken;
}

export function getQueryLoginFrom(url: string): string {
  const { query } = parse(url, true);
  const loginFrom = query[loginFromParam];
  return loginFrom;
}

/* @flow */

import type { Node } from 'react';

import { useIntl } from 'react-intl';

import {
  getGroupTopic,
  isGroupTopic,
  isUserTopicAuthor,
  isUserTopicCohost,
} from '@braindate/domain/lib/topic/util';
import type { User } from '@braindate/domain/lib/user/type';
import { parseFirstName, parseLastName } from '@braindate/domain/lib/user/util';

import messages from 'src/shared/app/base/plugin/virtual-braindate/l10n/virtualBraindateL10n';
import createUseThemeStyles from 'src/shared/app/base/util/createUseThemeStyles';

import useCustomVideoContext from 'plugin/virtual-braindate/component/hook/useCustomVideoContext';
import useScreenShareParticipant from 'plugin/virtual-braindate/hook/useScreenShareParticipant';
import { getIdentity } from 'plugin/virtual-braindate/util/virtualBraindateUtils';

import styles from './VirtualBraindateParticipantLabel.style';

const useStyles = createUseThemeStyles(styles);

export const AVATAR_POSITION_CENTER = 'center';
export const AVATAR_POSITION_CORNER = 'corner';

type Props = {
  user?: User | null,
  participant: ?any,
};

export const getTargetFontSize = (label: string) => {
  if (label.length > 25) {
    return 10;
  }
  return 12;
};

export const getFullLabel = (user: User) =>
  `${parseFirstName(user)} ${parseLastName(user)}`;

const VirtualBraindateParticipantLabel = ({
  user,
  participant,
}: Props): Node => {
  const { topic } = useCustomVideoContext();
  const identity = getIdentity(participant);
  const participantName = user ? getFullLabel(user) : identity;
  const fontSize = participantName ? getTargetFontSize(participantName) : 0;
  const isHost = user && isUserTopicAuthor(user, topic);
  const isCohost =
    user &&
    isGroupTopic(topic) &&
    isUserTopicCohost(user, getGroupTopic(topic));
  const screenShareParticipant = useScreenShareParticipant();

  const isSharingScreen =
    screenShareParticipant &&
    participant &&
    screenShareParticipant.identity === participant.identity;

  const intl = useIntl();
  const classes = useStyles({
    fontSize,
  });
  const host = isHost && <span>{intl.formatMessage(messages.host)}</span>;
  const cohost = isCohost && <span>{intl.formatMessage(messages.cohost)}</span>;

  const participatScreenShare = isSharingScreen && (
    <span className={classes.presenting}>
      {intl.formatMessage(messages.presenting)}
    </span>
  );

  return (
    <div className={classes.root}>
      <div className={classes.inner}>
        {participantName} {participatScreenShare || host || cohost}
      </div>
    </div>
  );
};

export default VirtualBraindateParticipantLabel;

import useFeatureDecisions from 'src/shared/app/feature/hook/useFeatureDecisions';
import useEvent from 'src/shared/domain/event/hook/useEvent';
import { useGetTogethernessQuery } from 'src/shared/app/base/api/endpoint/togethernessEndpoints';
import { isEmpty } from 'ramda';
import { skipToken } from '@reduxjs/toolkit/query';
import useMembership from 'src/shared/domain/membership/hook/useMembership';

export default function useTogethernessRedirection() {
  const membership = useMembership();
  const { isTogethernessEnabled } = useFeatureDecisions();
  const skipTogetherness =
    !isTogethernessEnabled || membership?.flags.togetherness_seen;
  const event = useEvent();
  const { data: togetherness } = useGetTogethernessQuery(
    !skipTogetherness ? { eventId: event.id } : skipToken,
  );
  const shouldRedirectToTogetherness =
    !skipTogetherness && togetherness !== undefined && !isEmpty(togetherness);

  return {
    shouldRedirectToTogetherness,
  };
}

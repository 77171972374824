import { useContext } from 'react';
import VirtualBraindateZoomContext from '../../contexts/VirtualBraindateZoomContext';
import ZoomVideo, { Stream } from '@zoom/videosdk';

export default function useZoomVideoContext() {
  const context = useContext(VirtualBraindateZoomContext);
  if (!context) {
    throw new Error(
      'useZoomVideoContext must be used within a VirtualBraindateZoomContext',
    );
  }
  return context;
}

/* @flow */

import { memo, useEffect, useState } from 'react';
import type { Node } from 'react';

import { defaultLimitPages } from 'src/shared/app/base/api/constant/apiConstant';
import type {
  ApiListResult,
  UseQuery,
} from 'src/shared/app/base/api/type/apiQueryType';
import InfiniteScroll, {
  type ManualListConfig,
} from 'src/shared/app/base/component/infiniteScoll/InfiniteScroll';
import FeedList from 'src/shared/ui/component/data-display/FeedList';
import FeedQueryPage from 'src/shared/ui/component/data-display/FeedQueryPage';

type Props<T> = {
  useQuery: UseQuery<ApiListResult<T>>,
  renderSkeleton: () => Node,
  renderItem: (item: T, previousItem?: T) => Node,
  layoutClass?: string,
  itemWidth?: number,
  defaultPage?: number,
  page?: number,
  grid?: boolean,
  emptyComponent?: Node,
  params?: Object,
  isCompact?: boolean,
  isFrozen?: boolean,
  manualConfig?: ManualListConfig,
  interstitialComponents?: Array<Node>,
  interstitialIndexes?: Array<number>,
  onPageChange?: (page: number) => void,
  onPageLoad?: (page: number) => void,
  onAllFetched?: () => void,
  pageSeparator?: (page: number) => Node,
  containerRef?: { current: HTMLElement | null },
};

function FeedQuery<T>({
  useQuery,
  renderSkeleton,
  renderItem,
  layoutClass,
  itemWidth,
  defaultPage,
  page,
  grid,
  emptyComponent,
  params = {},
  isCompact,
  isFrozen,
  manualConfig = { isActive: false },
  interstitialComponents,
  interstitialIndexes,
  onPageChange,
  onPageLoad,
  onAllFetched,
  pageSeparator,
  containerRef,
}: Props<T>): Node {
  const [currentPageState, setCurrentPage] = useState<number>(defaultPage || 1);
  const currentPage = page || currentPageState;

  const currentLimit = params.limit || defaultLimitPages;

  const { data, isLoading, isFetching } = useQuery(
    {
      ...params,
      page,
    },
    {
      skip: isFrozen,
      refetchOnMountOrArgChange: true,
      refetchOnReconnect: true,
    },
  );

  const count = data?.count || 0;

  useEffect(() => {
    if (!onPageChange && !page) return;
    // $FlowIssue
    onPageChange(currentPage);
  }, [currentPage]);

  const itemsLeftCount = data ? data.count - currentPage * currentLimit : 0;
  const itemsLeft = itemsLeftCount < 0 ? 0 : itemsLeftCount;
  const canQueryMore = !data || (count !== 0 && !!itemsLeft);

  const handleNext = () => {
    if (canQueryMore) {
      const nextPage = currentPage + 1;
      setCurrentPage(nextPage);
      if (onPageChange) {
        onPageChange(nextPage);
      }
    }
  };

  useEffect(() => {
    if (
      !isLoading &&
      !isFetching &&
      (count < currentLimit || !canQueryMore) &&
      !!onAllFetched
    ) {
      onAllFetched();
    }
  }, [isLoading, isFetching, count, canQueryMore]);

  if (data && !count && !!emptyComponent) {
    return emptyComponent;
  }

  return (
    <InfiniteScroll
      onNext={canQueryMore ? handleNext : undefined}
      manualConfig={{
        ...manualConfig,
        isActive: canQueryMore && manualConfig.isActive,
      }}
      itemsLeft={itemsLeft}
      isLazyFetching={isFetching}
      containerRef={containerRef}
    >
      <FeedList
        count={count}
        allFetched={!itemsLeft}
        itemWidth={itemWidth}
        grid={grid}
        layoutClass={layoutClass}
        isCompact={isCompact}
      >
        {Array.from(Array(currentPage).keys()).map((_, pageIndex) => (
          <FeedQueryPage
            useQuery={useQuery}
            renderItem={renderItem}
            pageSeparator={pageSeparator}
            params={params}
            page={pageIndex + 1}
            count={count}
            isFrozen={isFrozen}
            renderSkeleton={renderSkeleton}
            onPageLoad={onPageLoad}
            interstitialComponents={interstitialComponents}
            interstitialIndexes={interstitialIndexes}
            key={pageIndex}
          />
        ))}
      </FeedList>
    </InfiniteScroll>
  );
}

export default memo<Props<any>>(FeedQuery);

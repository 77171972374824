/* @flow */

import { useDispatch } from 'react-redux';

import useSidebar from 'src/shared/app/base/component/template/hooks/useSidebar';
import useTogethernessRedirection from 'src/shared/app/base/route/hook/useTogethernessRedirection';
import useFeatureDecisions from 'src/shared/app/feature/hook/useFeatureDecisions';

import useVirtualBraindate from 'plugin/virtual-braindate/hook/useVirtualBraindate';

export default function useRouteConfig(): Object {
  const {
    areEmailNotificationsEnabled,
    isSignupEnabled,
    isSsoFeatureEnabled,
    isOnboardingNextEnabled,
    isTogethernessEnabled,
  } = useFeatureDecisions();
  const dispatch = useDispatch();
  const isVirtualBraindateEnabled = useVirtualBraindate();
  const { shouldRedirectToTogetherness } = useTogethernessRedirection();

  const { hasSidebar } = useSidebar();

  return {
    dispatch,
    areEmailNotificationsEnabled,
    isSignupEnabled,
    isVirtualBraindateEnabled,
    hasSidebar,
    isSsoFeatureEnabled,
    shouldRedirectToTogetherness,
    isOnboardingNextEnabled,
    isTogethernessEnabled,
  };
}

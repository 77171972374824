/* @flow */

import { lap, tablet } from '@braindate/ui/lib/token/mediaQuery';

import { horizontalSmallDeviceBreakpoint } from 'src/shared/app/base/style/util/responsive';
import { topBarMarginBottom } from 'src/shared/app/nav/style/nav.style';

export const sideBarTabletWidth = 178;
export const sideBarLapWidth = 328;

export const mainTabletWidth = 530;
export const mainLapWidth = 580;

export const navBarMobileHeight = 72;

export const templateRoot = {
  display: 'flex',
  flexDirection: 'column',
  width: '100%',
  margin: [0, 'auto'],
  minHeight: '100vh',

  backgroundRepeat: 'no-repeat',
  backgroundPosition: '50% 100%',
  backgroundSize: 'cover',
  backgroundAttachment: 'fixed',
};

export const templateMain = {
  width: '100%',
  zIndex: 0,
};

export const templateAside = {
  position: 'fixed',
  bottom: 0,
  left: 0,
  right: 0,

  [tablet]: {
    position: 'static',
    width: 180,
    marginRight: '3em',
  },
};

export const templateContent = {
  flexGrow: 1,
  display: 'flex',
  flexDirection: 'column',

  [horizontalSmallDeviceBreakpoint]: {
    marginTop: 8,
  },

  [lap]: {
    marginTop: topBarMarginBottom,
  },
};

export const templateContainer = {
  display: 'flex',
  flexDirection: 'column',
};

export const templateContentInner = {
  flexGrow: 1,
};

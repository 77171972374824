/* @flow */
import { memo, useRef, useState } from 'react';

import { OutlinedFlag } from '@mui/icons-material';
import ChatBubbleOutlineOutlinedIcon from '@mui/icons-material/ChatBubbleOutlineOutlined';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import IosShareOutlinedIcon from '@mui/icons-material/IosShareOutlined';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';

import type { Braindate } from '@braindate/domain/lib/braindate/type';
import {
  getBraindateConversation,
  getBraindateiCalURL,
  getBraindateLastActiveUser,
  isBraindateCancelled,
} from '@braindate/domain/lib/braindate/util';
import {
  complaintTypeInvitation,
  complaintTypeTopic,
} from '@braindate/domain/lib/complaint/setting';
import {
  getConversationParticipants,
  getConversationPath,
} from '@braindate/domain/lib/conversation/util';
import { isLocationsOver } from '@braindate/domain/lib/location/util';
import type { Topic } from '@braindate/domain/lib/topic/type';
import {
  getGroupTopic,
  getOneOnOneTopic,
  getTopicBasedOnInterest,
  getTopicCancelURL,
  getTopicId,
  getTopicURL,
  isFishbowlTopic,
  isGroupTopic,
  isUserTopicAuthor,
} from '@braindate/domain/lib/topic/util';
import { getUserId } from '@braindate/domain/lib/user/util';

import { useTopicCancelMutation } from 'src/shared/app/base/api/endpoint/topicsEndpoint';
import { triggerErrorModal } from 'src/shared/app/base/modal/action/modalActions';
import { errorModalId } from 'src/shared/app/base/modal/constant/modalConstant';
import modalRegistry from 'src/shared/app/base/modal/registry/modalRegistry';
import { triggerModal } from 'src/shared/app/base/modal/slice/modalSlice';
import { topicEditRoute } from 'src/shared/app/base/route/setting/routeSettings';
import { generatePath } from 'src/shared/app/base/route/util/routeUtils';
import messages from 'src/shared/app/braindate/l10n/braindateL10n';
import useIsAppEmbedded from 'src/shared/app/integration/hook/useIsAppEmpedded';
import SocialSharingModal from 'src/shared/app/social/component/SocialSharingModal';
import { socialSharingModalId } from 'src/shared/app/social/constant/socialSharingConstant';
import useReportingModal from 'src/shared/components/domain/complaints/hooks/useReportingModal';
import RecommendationModal from 'src/shared/components/domain/user/form/RecommendationModal';
import {
  getBraindateTopic,
  isBraindatePast,
} from 'src/shared/domain/braindate/util/braindateUtil';
import useLocations from 'src/shared/domain/location/hook/useLocations';
import topicMessages from 'src/shared/domain/topic/l10n/topicL10n';
import {
  isTopicLive,
  isTopicPast,
} from 'src/shared/domain/topic/util/topicUtil';
import { userRecommendTopic } from 'src/shared/domain/user/action/userActions';
import useSelf from 'src/shared/domain/user/hook/useSelf';
import userMessages from 'src/shared/domain/user/l10n/userL10n';
import CalendarIcon from 'src/shared/ui/component/icon/CalendarIcon';
import type { MenuPopoverItemProps } from 'src/shared/ui/component/menu/MenuPopoverItem';
import OptionsMenu from 'src/shared/ui/component/menu/OptionsMenu';
import OptionsMenuButton from 'src/shared/ui/component/menu/OptionsMenuButton';

import useSocialSharing from 'plugin/social-sharing/hook/useSocialSharing';

modalRegistry.add(socialSharingModalId, SocialSharingModal);

type Props = {
  topic?: Topic,
  braindate?: Braindate,
  classes?: {
    root?: string,
    button?: string,
  },
};

const TopicMenu = ({ topic: topicProps, braindate, classes = {} }: Props) => {
  const intl = useIntl();
  const self = useSelf();
  const dispatch = useDispatch();
  const [isOpened, setIsOpened] = useState(false);
  const buttonRef = useRef();
  const isSocialSharingEnabled = useSocialSharing();
  const [cancelTopic, { isLoading: isCancelling }] = useTopicCancelMutation();
  const showReportModal = useReportingModal();

  if (!topicProps && !braindate) {
    throw new Error('Either braindate or topic must be defined');
  }

  const topic = braindate ? getBraindateTopic(braindate) : topicProps;
  const iCalLink = braindate && getBraindateiCalURL(braindate);
  const isAppEmbedded = useIsAppEmbedded();
  const conversation = braindate ? getBraindateConversation(braindate) : null;

  const lastBraindateInvitationUser =
    braindate && getBraindateLastActiveUser(braindate);

  if (!topic) {
    throw new Error('Topic was undefined');
  }

  const topicId = getTopicId(topic);
  const isGroupBraindate = isGroupTopic(topic);
  const isFishbowl = isFishbowlTopic(topic);
  const isPast = !!isGroupTopic(topic) && isTopicPast(getGroupTopic(topic));
  const isLive = isTopicLive(topic);
  const isSelfTopicAuthor = !!self && isUserTopicAuthor(self, topic);

  const isConnectOnInterest =
    !isGroupBraindate &&
    !!getTopicBasedOnInterest(getOneOnOneTopic(topic), intl.locale);

  const locations = useLocations();
  const isEventOver = isLocationsOver(locations);

  const [showRecommendModal, setShowRecommendModal] = useState<boolean>(false);

  const isSelfLastBraindateInvitation =
    self &&
    lastBraindateInvitationUser &&
    getUserId(lastBraindateInvitationUser) === getUserId(self);

  const showReportButton =
    !isSelfLastBraindateInvitation || (!braindate && isSelfTopicAuthor);

  const conversationParticipants = conversation
    ? getConversationParticipants(conversation)
    : [];
  const userJoinedConversation: boolean =
    !!self &&
    conversationParticipants.some(
      (participant) => getUserId(self) === getUserId(participant),
    );

  const showRecommedTopicModal = () => {
    setShowRecommendModal(true);
  };
  const handleModalClose = () => {
    setShowRecommendModal(false);
    if (buttonRef.current) {
      buttonRef.current.focus();
    }
  };
  const handleSubmitRecommendation = async (values) => {
    const { user: receiverUser, message } = values;

    await dispatch(
      userRecommendTopic(getUserId(receiverUser), getTopicURL(topic), message),
    );
  };

  const showSocialSharingModal = () => {
    dispatch(
      triggerModal(socialSharingModalId, {
        topicId,
      }),
    );
  };

  const menuItems: MenuPopoverItemProps[] = [
    ...(conversation && userJoinedConversation && !isFishbowl
      ? [
          {
            title: intl.formatMessage(
              isPast ? messages.viewMessages : messages.sendMessage,
            ),
            icon: <ChatBubbleOutlineOutlinedIcon />,
            to: getConversationPath(conversation),
          },
        ]
      : []),
    ...(isSocialSharingEnabled &&
    !isPast &&
    !isConnectOnInterest &&
    isSelfTopicAuthor
      ? [
          {
            title: intl.formatMessage(messages.share),
            icon: <IosShareOutlinedIcon />,
            onClick: showSocialSharingModal,
          },
        ]
      : []),
    ...((!braindate || !isBraindateCancelled(braindate)) &&
    isSelfTopicAuthor &&
    !isPast &&
    !isConnectOnInterest
      ? [
          {
            title: intl.formatMessage(messages.editTopic),
            icon: <ModeEditOutlineOutlinedIcon />,
            to: generatePath(topicEditRoute, { id: topicId }),
          },
        ]
      : []),
    ...(isAppEmbedded && iCalLink
      ? [
          {
            title: intl.formatMessage(messages.addToCalendar),
            icon: <CalendarIcon />,
            to: iCalLink,
          },
        ]
      : []),
    ...(!isConnectOnInterest &&
    !isEventOver &&
    (isGroupBraindate ? !isPast && !isLive : true)
      ? [
          {
            title: intl.formatMessage(messages.recommend),
            icon: <EmailOutlinedIcon />,
            onClick: showRecommedTopicModal,
          },
        ]
      : []),
    ...((!braindate || !isBraindatePast(braindate)) && getTopicCancelURL(topic)
      ? [
          {
            title: intl.formatMessage(messages.cancelBraindate),
            icon: <CloseOutlinedIcon />,
            onClick: async () => {
              try {
                await cancelTopic(topic);
              } catch (error) {
                dispatch(triggerErrorModal(errorModalId, error));
              }
            },
            isDisabled: isCancelling,
          },
        ]
      : []),
    ...(showReportButton
      ? [
          {
            title:
              !isSelfLastBraindateInvitation &&
              (!braindate || !isBraindatePast(braindate))
                ? intl.formatMessage(messages.reportInvitation)
                : intl.formatMessage(topicMessages.reportTopic),
            icon: <OutlinedFlag />,
            onClick: () => {
              if (!braindate && !topic) return;
              showReportModal(
                !isSelfLastBraindateInvitation
                  ? complaintTypeInvitation
                  : complaintTypeTopic,
                // $FlowIssue Both of these will be defined
                isSelfLastBraindateInvitation ? braindate : topic,
              );
            },
          },
        ]
      : []),
  ];

  return (
    <>
      <OptionsMenuButton
        ref={buttonRef}
        className={classes.button}
        onClick={() => setIsOpened(true)}
      />

      {buttonRef.current ? (
        <OptionsMenu
          isOpen={isOpened}
          options={menuItems}
          anchor={buttonRef.current}
          onClose={() => setIsOpened(false)}
          className={classes.root}
        />
      ) : null}

      {showRecommendModal ? (
        <RecommendationModal
          title={intl.formatMessage(userMessages.recommendTopicTitle)}
          onSubmit={handleSubmitRecommendation}
          onClose={handleModalClose}
          onAfterClose={handleModalClose}
          topic={topic}
        />
      ) : null}
    </>
  );
};

export default memo<Props>(TopicMenu);

/* @flow */

import { useContext } from 'react';

import type { CustomVideoContext } from 'src/shared/app/base/plugin/virtual-braindate/type/virtualTypes';

import Context from 'plugin/virtual-braindate/context/virtualBraindateContext';

export default function useCustomVideoContext(): CustomVideoContext {
  const context = useContext(Context);
  if (!context) {
    throw new Error('useVideoContext must be used within a VideoProvider');
  }
  return context;
}
